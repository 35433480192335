import "./services.css"

export default function services() {
    return(<>
        <div className="leftbox">
            Services we offer
        </div>
        <div className="right-box">
            LIST OF RESTAURANTS SO FAR
        </div>
        </>
    )
}