import "./styler.css"

export default function about() {
    return(
        <>
        <div className="about">
            <div className="questt">
                SO WHAT IS TIFFIi? 
            </div>
        </div>
        <div className="aboutt">
            <div>
                So what is Tiffii? 
            </div>
            <div>
                Tiffii is the platform for you to sell your tiffins! Reach out to your customers!
            </div>
            <p1>
                Are you a student? Or maybe a full time worker? A bachelor? Or anyone who does not have the time
                to cook due to jobs and other reasons? Then you have come at the perfect place for tiffins! 
            </p1>
            <p1>
                Tiffins are meals made by wonderful chefs from different restuarants, ghost ktichens etc. and 
                delivered to you right at your doorsteps! You can tailor the choices as per your wish, and have
                multiple pricing options as well.
            </p1>
        </div>
        <ul>
            <li>
                OUR MISSION
            </li>
            <li>
                EXPLORE OUR BUSINESS OPTIONS
            </li>
            <li>
                business with restaurant chains
            </li>
            <li>
                small propreiterships
            </li>
        </ul>
        <div>
            FAQs
        </div>
        </>
    )
}