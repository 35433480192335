import Navbar from './components/Navigation/Navbar';
import Footer from "./components/Footer/footer";
import Home from "./components/Main pages/body";
import About from './components/Main pages/About';
import Services from "./components/Main pages/services";
import Register from './components/Main pages/register';
import Login from "./components/Main pages/login";
import Signup from "./components/Main pages/signup";
import { Route, Routes } from 'react-router-dom';

export default function App() {
  return (
      <div className='app'>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/home/register' element={<Register />} />
          <Route path='/services' element={<Services />} /> 
          <Route path='/about' element={<About />} />
          <Route path='/services' element={<Services />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
        </Routes>
        <Footer />
      </div>
  )
}

