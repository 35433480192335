import background from "./images/bkgrnd.jpeg"
import "./styler.css"
import "./login.css"

export default function login() {
    return(
        <>
        <div style={{
             backgroundImage: `url(${background})`,
             backgroundRepeat: `no-repeat` 
             }} className="log-cont">
            <div className="log-cont-dub">
                <h1>
                    Login
                </h1>
                <h2>
                    Enter your E-mail
                </h2>
                <input type="email"></input>

                <h2>Enter your password</h2>
                <input type="password"></input>

                <h1>
                    <button className="subbit">
                        Login
                    </button>
                </h1>
            </div>
        </div>
        </>
    )
}
