import "./footer.css"

export default function footer() {
    return(
    <>
    <div className="footer">
        <ul className="content">
            <h1>
                Contact us: 
            </h1>
            <li>
                Contact us via our email : Contact@tiffii.ca
            </li>
            <li>
                <a href="/privacy">
                    Privacy Policy
                </a>
            </li>
            <li>
                <a href="/termsofservice">
                    Terms of Service
                </a>
            </li>
            <li>
                <a href="/about">
                    FAQs
                </a>
            </li>
        </ul>

        </div>
        </>
    )
}