import "./styler.css"
import React, { useRef } from 'react';
import welcbkg from "./images/bgbg.jpg"
import sinreg from "./images/forrest.avif"
import leaf from "./images/peacock.png"

    

export default function Body() {
    const dubbeRef = useRef(null);
  
    const scrollToDubbe = () => {
        const offset = -3; 
        const element = dubbeRef.current;
        const y = element.getBoundingClientRect().top + window.pageYOffset + offset;
      
        window.scrollTo({ top: y, behavior: 'smooth' });
      };
      
    return (<>
    
        <div className="welcome" style={{
                backgroundImage: `url(${welcbkg})`,
                backgroundRepeat: 'no-repeat',
             }}>
            <div className="welc-cc">
                <div>
                    Welcome to Tiffii!
                </div>
                <div className="lst">
                <button className="start" onClick={scrollToDubbe}>
                    Get started
                </button>
                </div>
            </div>
        </div>
        <div className="whatrwe">
            <div>
                So what is Tiffii? 
            </div>
            <div>
                Tiffii is the platform for you to buy or sell your tiffins! Reach out to your customers!
            </div>
            <div>
                Are you a student? Or maybe a full time worker? A bachelor? Or anyone who does not have the time
                to cook due to jobs and other reasons? Then you have come at the perfect place for tiffins! 
            </div>
            <div>
                Tiffins are meals made by wonderful chefs from different restuarants, ghost ktichens etc. and 
                delivered to you right at your doorsteps! You can tailor the choices as per your wish, and have
                multiple pricing options as well.
            </div>
        </div>
        <ul className="dubbe" ref={dubbeRef} style={{
            backgroundImage: `url(${sinreg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover' 
            }}>
            <li className="left-box">
                <a href="/home/register">
                Register your business with us?
                </a>
            </li>
            <li className="right-box">
                <a href="/services">
                    Looking for a tiffin?
                </a>
            </li>
        </ul>
        <div className="whyus" style={{
                backgroundImage: `url(${leaf})`,
                backgroundRepeat: 'no-repeat',
             }}>
            <div className="whyus-left">
            Why choose us?
            </div>
            <div className="whyus-right">
            <ul>
                <li>
                    The best deals you can find! Most affordable tiffins
                </li>
                <li>
                    Easy to start, simple processes
                </li>
                <li>
                    Great food quality!
                </li>
            </ul>
            </div>
        </div>
        </>
    )
}