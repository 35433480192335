import "./Navbar.css"
import Logo from "./Logo P1-2.png"

export default function Navbar() {
    return (
        <div className="navbody">
            <li className="headline">
                <a href="/">
                    <img src={Logo} height={100} width={100} className="headhead" alt="LOGO"></img>
                </a>
            </li>
            <li className="headline">
                <div>
                    Need a Tiffin? Get Tiffii!
                </div>
                </li>
            <ul className="nav">
                
                <li>
                    <a href="/about">Pricing</a>
                </li>
                <li>
                    <a href="/services">Tiffins</a>
                </li>
                <li>
                    <a href="/login">
                    <button className="reglog">
                        Login
                    </button>
                    </a>
                </li>
                <li>
                    <a href="/signup">
                    <button className="reglog">
                        Sign Up
                    </button>
                    </a>
                </li>
            </ul>
        </div>
    );
} 

