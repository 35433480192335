import background from "./images/bkgrnd.jpeg"
import "./styler.css"
import "./signup.css"

export default function signup() {
    return(
        <>
        <div style={{ backgroundImage: `url(${background})` }} className="signup-cont">
            <div className="signup-form">
                    <h1>
                        Signup
                    </h1>
                    <h2>
                        Enter your Name
                    </h2>
                    <input type="text"></input>

                    <h2>
                        Enter your E-mail
                    </h2>
                    <input type="email"></input>

                    <h2>Enter your password</h2>
                    <input type="password"></input>

                    <h2>Confirm your password</h2>
                    <input type="password"></input>
                    <h1>
                        <button className="subbit">
                            Submit
                        </button>
                    </h1>
            </div>
        </div>
        </>
    )
}